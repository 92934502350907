import React, { useState } from "react";

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(-1); // Index of the open question, -1 means none are open

  const questions = [
    // Questions with HTML for email link
    {
      question: "Qu'est-ce que Displex ?",
      answer:
        "Displex est une plateforme innovante de gestion d'affichage dynamique. Elle vous permet de diffuser et de programmer des contenus visuels sur différents écrans à distance, offrant ainsi une solution efficace pour optimiser votre communication visuelle.",
    },
    {
      question: "Quels sont les avantages ?",
      answer:
        "Displex simplifie la gestion de vos contenus visuels en vous permettant de les diffuser et de les programmer à distance. Vous pouvez gérer plusieurs écrans depuis une seule plateforme, économisant ainsi du temps et des ressources. De plus, Displex offre une interface conviviale et des fonctionnalités puissantes pour une expérience optimale.",
    },
    {
      question: "Comment puis-je commencer ?",
      answer:
        "Pour commencer avec Displex, il vous suffit de vous rendre dans la section tarifaire de notre site web et de choisir la formule qui convient le mieux à vos besoins. Une fois inscrit, vous pourrez ajouter vos écrans, télécharger vos contenus et commencer à les diffuser en quelques étapes simples.",
    },
    {
      question: "Quels types de contenus puis-je diffuser ?",
      answer:
        "Vous pouvez diffuser une variété de contenus visuels avec Displex, y compris des images, des vidéos, des présentations et du contenu interactif. Notre plateforme prend en charge différents formats de fichiers pour répondre à vos besoins de communication.",
    },
    {
      question: "Est-ce compatible avec tous les types d'écrans ?",
      answer:
        "Displex est conçu pour être compatible avec la plupart des écrans disposant d'un port HDMI. Que ce soit des téléviseurs, des écrans d'affichage public, des moniteurs ou d'autres dispositifs similaires, notre solution s'adapte facilement à différents environnements d'affichage.",
    },
    {
      question: "Comment contacter le support clientèle ?",
      answer:
        "Pour contacter notre équipe d'assistance, vous pouvez nous envoyer un e-mail à <span style='font-weight: 500;'><a href='mailto:contact@displex.fr'>contact@displex.fr</a></span>. Nous sommes là pour répondre à toutes vos questions et vous aider en cas de besoin.",
    },
  ];

  const toggleAnswer = (index) => {
    if (openIndex === index) {
      setOpenIndex(-1); // Toggle close the currently open question
    } else {
      setOpenIndex(index); // Toggle open the clicked question
    }
  };

  return (
    <section className="bg-white bg-base-200" id="faq">
      <div className="py-24 px-8 max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-12">
          <div className="flex flex-col text-left md:basis-1/2">
            <p className="inline-block font-semibold text-primary mb-4">FAQ</p>
            <p className="text-3xl md:text-4xl font-extrabold text-base-content">
              Questions Fréquemment Posées
            </p>
          </div>
          <ul className="basis-1/2">
            {questions.map((q, index) => (
              <li key={index}>
                <button
                  className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
                  aria-expanded={openIndex === index}
                  onClick={() => toggleAnswer(index)}
                >
                  <span className="flex-1 text-base-content">{q.question}</span>
                  <svg
                    className={`flex-shrink-0 w-4 h-4 ml-auto fill-current transform transition duration-200 ${
                      openIndex === index ? "" : "rotate-180" // Rotate when closed
                    }`}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                      className="origin-center"
                    />
                    <rect
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                      className={`origin-center rotate-90 ${
                        openIndex === index ? "hidden" : ""
                      }`}
                    />
                  </svg>
                </button>
                <div
                  className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
                  style={{
                    maxHeight: openIndex === index ? "226px" : "0px",
                    opacity: openIndex === index ? 1 : 0,
                  }}
                >
                  <div className="pb-5 leading-relaxed">
                    <div className="space-y-2 leading-relaxed">
                      {/* Use dangerouslySetInnerHTML to render HTML content */}
                      <p dangerouslySetInnerHTML={{ __html: q.answer }} />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
