import React from "react";
import { useNavigate } from "react-router-dom";
import displexLogo from "../images/logo.png";
import damienAubry from "../images/Damien AUBRY.jpg";
import "bootstrap-icons/font/bootstrap-icons.css";
import { navigateAndScroll } from "../utils/scrollUtils";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <footer className="bg-base-300/50 border-t border-base-content/10 bg-black text-white">
      <div className="max-w-7xl mx-auto px-8 py-24">
        <div className="flex lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <a
              aria-current="page"
              className="flex gap-2 justify-center md:justify-start items-center"
              href="/#"
            >
              <img src={displexLogo} alt="Displex logo" className="w-6 h-6" />
              <strong className="font-extrabold tracking-tight text-base md:text-lg">
                Displex
              </strong>
            </a>
            <p className="mt-3 text-sm text-base-content/80">
              Transformez votre communication visuelle. Diffusez et programmez
              vos contenus à distance, simplement.
            </p>
            <p className="mt-3 text-sm text-base-content/60">
              Copyright © {currentYear}
            </p>
          </div>
          <div className="flex-grow flex flex-wrap justify-center -mb-10 md:mt-0 mt-10 text-center md:pl-24">
            {/* Liens */}
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                LIENS
              </div>
              <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                <button
                  onClick={() => navigateAndScroll(navigate, "features")}
                  className="link link-hover"
                >
                  Fonctionnalités
                </button>
                <button
                  onClick={() => navigateAndScroll(navigate, "pricing")}
                  className="link link-hover"
                >
                  Tarification
                </button>
                <a
                  href="https://tally.so/r/wLGod2"
                  className="link link-hover"
                  aria-label="Contact Support"
                >
                  Support
                </a>
              </div>
            </div>
            {/* Légal */}
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                LÉGAL
              </div>
              <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                <a href="/terms" className="link link-hover">
                  Conditions Générales de Vente
                </a>

                <a href="/privacy-policy" className="link link-hover">
                  Politique de Confidentialité
                </a>
                <a href="/legal-mentions" className="link link-hover">
                  Mentions Légales
                </a>
              </div>
            </div>
            {/* Plus */}
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">
                PLUS
              </div>
              <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                {/*<a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link link-hover"
                >
                  Newsletter des créateurs
                </a>*/}
                <a
                  href="https://aubrymedia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link link-hover"
                >
                  AubryMedia
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="mt-12 md:mt-16 text-sm">
          <div className="flex flex-row justify-start items-center gap-4">
            <img
              alt="Damien AUBRY"
              className="rounded-full w-8 aspect-square"
              src={damienAubry}
            />
            <div className="text-left text-base-content-secondary leading-relaxed">
              Salut 👋 C'est{" "}
              <a
                href="https://www.instagram.com/damien_abr/"
                target="_blank"
                rel="noopener noreferrer"
                className="link text-base-content font-medium"
              >
                Damien
              </a>
              , fondateur de{" "}
              <a
                href="https://www.instagram.com/displx"
                target="_blank"
                rel="noopener noreferrer"
                className="link text-base-content font-medium"
              >
                Displex
              </a>
              . Tu peux suivre mon travail sur{" "}
              <a
                href="https://twitter.com/damien_abr"
                target="_blank"
                rel="noopener noreferrer"
                className="link text-base-content font-medium"
              >
                <i className="bi bi-twitter-x"></i>
              </a>
            </div>
          </div>
        </div>*/}
      </div>
    </footer>
  );
};

export default Footer;
