import React from "react";

const Features = () => {
  const features = [
    {
      title: "Création de playlist en fonction des jours et des dates",
      description:
        "Automatisez la diffusion de vos contenus en créant des playlists personnalisées qui s'adaptent aux jours spécifiques ou à des dates prédéterminées, assurant une gestion efficace et sans effort de vos campagnes d'affichage.",
    },
    {
      title: "Gestion à distance du player",
      description:
        "Contrôlez et gérez vos players d'affichage à distance depuis n'importe quel endroit grâce à notre interface web, vous permettant de mettre à jour et de synchroniser vos contenus en temps réel sans nécessiter de présence physique.",
    },
    {
      title: "Player vidéo prêt à l'emploi",
      description:
        "Intégrez un lecteur vidéo plug-and-play, sans aucune configuration nécessaire, prêt à diffuser vos contenus instantanément.",
    },
    {
      title: "Gestion de plusieurs écrans",
      description:
        "Simplifiez la gestion de plusieurs écrans à travers différentes localisations avec une seule plateforme centralisée, facilitant la surveillance et la maintenance de vos installations d'affichage dynamique.",
    },
  ];

  return (
    <section className="bg-white bg-base-200 py-24 px-8" id="features">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <p className="inline-block font-semibold text-primary mb-4">
            FONCTIONNALITÉS
          </p>
          <p className="text-3xl md:text-4xl font-extrabold text-base-content">
            Découvrez ce que Displex peut faire
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-10">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300"
            >
              <h3 className="text-2xl font-bold text-primary mb-3">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
