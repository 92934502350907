// SupportConfirmation.js
import React from "react";
import { useNavigate } from "react-router-dom";

const SupportConfirmation = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/", { replace: true });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow bg-gray-100 p-5">
      <h1 className="text-3xl font-bold mb-5">Merci de nous avoir contacté</h1>
      <p className="text-lg text-center mb-8">
        Votre message a été envoyé avec succès. Nous vous répondrons dans les
        plus brefs délais.
      </p>
      <button
        onClick={goToHome}
        className="bg-gradient-to-r from-indigo-500 to-pink-500 text-white font-bold py-3 px-6 rounded-lg hover:bg-pink-500 transition duration-300"
      >
        Retour à la page d'accueil
      </button>
    </div>
  );
};

export default SupportConfirmation;
