import React, { useState, useEffect } from "react";
import { MdMenu, MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import DisplexText from "./DisplexText"; // Texte vectorisé
import { navigateAndScroll } from "../utils/scrollUtils";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const scrollToTop = () => {
    navigate("/", { replace: true });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (menuOpen) {
      window.addEventListener("scroll", closeMenu);
    } else {
      window.removeEventListener("scroll", closeMenu);
    }

    return () => {
      window.removeEventListener("scroll", closeMenu);
    };
  }, [menuOpen]);

  return (
    <header className="flex items-center justify-between px-20 py-5 text-black bg-white border-b border-gray-200 top-0 z-50">
      <div className="flex items-center justify-start w-1/4">
        <button
          onClick={scrollToTop}
          className="flex items-center cursor-pointer"
        >
          <DisplexText className="text-4xl md:text-5xl font-bold" />{" "}
          {/* Texte vectorisé plus grand et bold */}
        </button>
      </div>

      <nav className="hidden md:flex items-center justify-center w-1/2 space-x-4">
        <button
          onClick={() => navigateAndScroll(navigate, "features")}
          className="hover:text-gray-300 font-medium"
        >
          Fonctionnalités
        </button>
        <button
          onClick={() => navigateAndScroll(navigate, "pricing")}
          className="hover:text-gray-300 font-medium"
        >
          Tarifs
        </button>
        <button
          onClick={() => navigateAndScroll(navigate, "faq")}
          className="hover:text-gray-300 font-medium"
        >
          FAQ
        </button>
      </nav>

      <div className="hidden md:flex items-center justify-end w-1/4">
        <a
          href="https://app.displex.fr"
          className="flex items-center justify-center font-bold py-3 px-6 bg-gray-900 text-white rounded-full transition duration-300 ease-in-out transform hover:scale-105"
        >
          Se connecter
        </a>
      </div>

      <div className="flex items-center md:hidden">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="focus:outline-none"
        >
          {menuOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
        </button>
      </div>

      {menuOpen && (
        <nav className="absolute top-16 left-0 w-full bg-white flex flex-col items-center space-y-4 p-5 md:hidden">
          <button
            onClick={() => {
              navigateAndScroll(navigate, "features");
              closeMenu();
            }}
            className="hover:text-gray-300 font-medium"
          >
            Fonctionnalités
          </button>
          <button
            onClick={() => {
              navigateAndScroll(navigate, "pricing");
              closeMenu();
            }}
            className="hover:text-gray-300 font-medium"
          >
            Tarifs
          </button>
          <button
            onClick={() => {
              navigateAndScroll(navigate, "faq");
              closeMenu();
            }}
            className="hover:text-gray-300 font-medium"
          >
            FAQ
          </button>
          <a
            href="https://app.displex.fr"
            className="flex items-center justify-center font-bold py-3 px-6 bg-gray-900 text-white rounded-full transition duration-300 ease-in-out transform hover:scale-105"
          >
            Se connecter
          </a>
        </nav>
      )}
    </header>
  );
};

export default Header;
