import React, { useEffect, useState } from "react";
import dashboardImage from "../images/dashboard-displex.jpg"; // Adjust the path accordingly
import backgroundHome from "../images/background-home-displex.jpg"; // Adjust the path accordingly
import mobileImage from "../images/dashboard-displex-mobile.jpg"; // Adjust the path accordingly

const Display = () => {
  const [tilt, setTilt] = useState(15); // Initial tilt angle
  const [scale, setScale] = useState(1); // Initial scale (zoomed out)
  const [isMobile, setIsMobile] = useState(false); // To check if it's mobile

  const handleScroll = () => {
    if (!isMobile) {
      const scrollY = window.scrollY;
      // Adjust sensitivity to synchronize tilt and scale changes
      const tiltSensitivity = 40; // Higher value slows down the tilt
      const scaleSensitivity = 4000; // Higher value slows down the zoom

      const newTilt = Math.max(0, 15 - scrollY / tiltSensitivity);
      const newScale = Math.min(1, 0.9 + scrollY / scaleSensitivity);

      setTilt(newTilt);
      setScale(newScale);
    }
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", checkIsMobile);
    };
  }, [isMobile]);

  return (
    <section
      className={`relative ${
        isMobile ? "h-[50vh]" : "min-h-screen"
      } flex items-center justify-center overflow-visible`}
      style={{ padding: 0 }}
    >
      {isMobile ? (
        <img
          src={mobileImage}
          alt="Mobile Dashboard"
          className="w-full h-full object-contain"
          style={{ margin: 0, padding: 0 }}
        />
      ) : (
        <>
          <div className="absolute inset-0 overflow-hidden h-full">
            <img
              src={backgroundHome}
              alt="Background"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="relative container mx-auto h-full flex justify-center items-center">
            <div
              className="transform transition-transform duration-1000 ease-out rotate-tilt-scale"
              style={{
                transform: `perspective(1000px) rotateX(${tilt}deg) scale(${scale})`,
                willChange: "transform",
                position: "relative",
                top: "-10vh", // Adjust this value to move the image up or down
                zIndex: 10, // Ensure this element is above others
              }}
            >
              <img
                src={dashboardImage}
                alt="Dashboard"
                className="w-full max-w-90 mx-auto rounded-lg shadow-lg"
                style={{
                  maxWidth: "90%",
                  borderRadius: "10px",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                }}
              />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Display;
