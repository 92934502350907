import React from "react";

const TermsOfService = () => {
  return (
    <div className="bg-gray-50 text-black p-5 mb-16 lg:p-10">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-10">
          Conditions Générales de Vente (CGV)
        </h1>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">1. Préambule</h2>
          <p>
            Ces conditions générales de vente s'appliquent exclusivement entre
            la SAS Displex, située au 36 rue de Grandvilliers, 60000 Beauvais,
            France, immatriculée au Registre du Commerce et des Sociétés de
            Beauvais sous le numéro 932 139 710, et toute personne visitant ou
            effectuant un achat via les sites{" "}
            <a
              href="https://displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              https://displex.fr
            </a>{" "}
            ou{" "}
            <a
              href="https://app.displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              https://app.displex.fr
            </a>
            .
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">2. Objet</h2>
          <p>
            Ces conditions définissent les modalités de vente entre Displex et
            le client, de la commande aux services, en passant par le paiement
            et la livraison.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">3. Commandes</h2>
          <p>
            Les commandes peuvent être passées sur les sites{" "}
            <a
              href="https://displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              https://displex.fr
            </a>{" "}
            ou{" "}
            <a
              href="https://app.displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              https://app.displex.fr
            </a>
            . Le processus de commande inclut le choix du produit, la validation
            du panier, l'identification, le choix du paiement, et la
            confirmation finale de la commande.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">4. Produits</h2>
          <p>
            Les produits proposés à la vente sont ceux décrits sur les pages
            spécifiques des produits sur le site. Displex prend grand soin dans
            la présentation et la description des produits pour garantir
            l'exactitude des informations fournies aux clients.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">5. Prix</h2>
          <p>
            Les prix sont indiqués en euros et sont hors taxes. Displex se
            réserve le droit de modifier ses prix à tout moment mais s'engage à
            appliquer les tarifs en vigueur indiqués au moment de la commande.
            La TVA applicable et toute autre taxe seront ajoutées lors de la
            finalisation de la commande, conformément à la réglementation en
            vigueur.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">6. Paiement</h2>
          <p>
            Le paiement est exigible immédiatement à la date de la commande. Les
            paiements peuvent être réalisés par carte de crédit via Stripe ou
            d'autres moyens sécurisés. Les transactions sont protégées par des
            protocoles de sécurité SSL pour garantir la confidentialité des
            informations transactionnelles des clients.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">7. Livraison</h2>
          <p>
            Les abonnements SaaS sont accessibles immédiatement via un compte
            utilisateur dès la validation du paiement. Aucune livraison physique
            n'est prévue pour ces services. Pour les players physiques, les
            détails de la livraison seront fournis par email et dans la section
            'Mes commandes' du tableau de bord de l'utilisateur.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">
            8. Droit de rétractation
          </h2>
          <p>
            Les consommateurs disposent de 14 jours pour se rétracter après la
            réception d'un bien physique, avec les frais de retour à leur
            charge. Les produits doivent être retournés dans leur état
            d'origine.
          </p>
          <p>
            Pour les abonnements SaaS, aucun remboursement n'est possible après
            l'activation immédiate. En validant leur commande, les utilisateurs
            renoncent expressément à leur droit de rétractation pour les
            services numériques fournis immédiatement, conformément à l’article
            L221-28 du Code de la consommation.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">
            9. Garanties et responsabilités
          </h2>
          <p>
            Displex respecte la garantie légale de conformité et contre les
            vices cachés, conformément aux articles L.217-4 à L.217-14 et
            articles 1641 à 1648 du Code civil. Les produits physiques
            bénéficient d'une garantie de deux ans contre les défauts de
            fabrication ou de non-conformité dès la livraison.
          </p>
          <p>
            Pendant les six premiers mois suivant la livraison, tout défaut qui
            apparaît est présumé exister au moment de la livraison. Displex
            s'engage à réparer ou remplacer tout produit défectueux sans frais
            supplémentaires pour le client, incluant les frais de retour, les
            pièces et la main-d'œuvre. Si la réparation ou le remplacement n'est
            pas possible, un remboursement complet ou partiel pourra être
            proposé.
          </p>
          <p>
            La garantie ne couvre pas les dommages résultant d'une mauvaise
            utilisation, d'accidents, de modifications non autorisées, ou de
            l'usure normale.
          </p>
          <p>
            En cas de découverte d'un vice caché, le client a le choix entre
            demander l'annulation de la vente et obtenir un remboursement
            complet, ou conserver le produit et demander une réduction du prix
            de vente. Displex décline toute responsabilité pour les dommages
            indirects qui pourraient résulter de l'utilisation des produits.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">10. Litiges</h2>
          <p>
            Tout litige relatif à l'interprétation et à l'exécution de ces
            conditions générales de vente est soumis au droit français. En cas
            de litige, une solution amiable sera recherchée avant toute action
            judiciaire. Conformément à l'article L612-1 du Code de la
            consommation, les consommateurs peuvent faire appel à un médiateur
            agréé pour résoudre le litige avant de recourir à un tribunal.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">
            11. Modifications des conditions générales de vente
          </h2>
          <p>
            Displex se réserve le droit de modifier ces conditions à tout
            moment. Les modifications seront effectives dès leur publication sur
            nos sites et seront communiquées aux clients par email ou via une
            notification sur le site. Les nouvelles CGV seront applicables aux
            ventes réalisées postérieurement à la modification.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mt-5 mb-2">
            12. Politique de remboursement et retours
          </h2>
          <p>
            Les abonnements SaaS, activés immédiatement après la validation du
            paiement, ne sont pas remboursables. Les clients doivent accepter
            explicitement cette condition en validant les CGV avant de finaliser
            le paiement. Si un service est jugé défectueux dès le départ, les
            clients doivent contacter le support client sans délai par mail à{" "}
            <a href="mailto:contact@displex.fr">contact@displex.fr</a>, et
            Displex s'engage à résoudre le problème ou offrir une compensation
            adaptée.
          </p>
          <p>
            Pour les players physiques, les clients bénéficient d'un droit de
            rétractation de 14 jours dès la réception du produit. Les frais de
            retour sont à la charge du client. En cas de retour d'un produit
            endommagé non dû à un défaut préexistant, une déduction pourra être
            appliquée sur le remboursement pour couvrir la dépréciation du
            produit.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
