// src/utils/scrollUtils.js
export const navigateAndScroll = (navigate, id) => {
  navigate("/", { replace: true });
  setTimeout(() => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, 100); // Délai pour permettre le changement de route avant le défilement
};
