import React from "react";

function PrivacyPolicy() {
  return (
    <div className="bg-gray-50 text-black p-5 mb-16 lg:p-10">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-10">
          Politique de Confidentialité et Gestion des Données Personnelles
        </h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            1. Collecte des données personnelles
          </h2>
          <p className="mb-2">
            Lors de l'utilisation des sites Displex (https://displex.fr et
            https://app.displex.fr), certaines données personnelles peuvent être
            collectées : nom, prénom, adresse électronique, numéro de téléphone,
            données de connexion et données de navigation, commandes,
            préférences et intérêts, ainsi que l'adresse IP.
          </p>
          <p>
            La collecte de ces données est nécessaire pour permettre une
            navigation adéquate sur nos sites, la gestion des commandes, ainsi
            que pour l'amélioration de nos services et communications.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            2. Utilisation des données personnelles
          </h2>
          <p>
            Les données collectées sur nos sites sont utilisées pour le
            traitement des commandes, la gestion des comptes clients, l'analyse
            statistique, le marketing et la publicité ciblée, la communication
            avec les clients et la personnalisation des contenus.
          </p>
          <p>
            Displex s'engage à ne pas vendre, partager ou divulguer vos données
            à des tiers en dehors du groupe Displex, sauf si nécessaire pour le
            traitement des commandes et la fourniture des services (par exemple,
            banques, services de livraison).
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            3. Sécurité et protection des données
          </h2>
          <p>
            Displex prend des mesures strictes de sécurité pour protéger les
            données personnelles contre les accès non autorisés, l'utilisation,
            la modification ou la destruction non autorisée. Nous utilisons des
            technologies de sécurité avancées et des procédures strictes pour
            protéger vos données personnelles.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            4. Droits des utilisateurs
          </h2>
          <p>
            Conformément à la réglementation applicable en matière de protection
            des données, vous disposez des droits suivants concernant vos
            données personnelles : droit d'accès, de rectification, de
            suppression, de limitation du traitement et de portabilité des
            données.
          </p>
          <p>
            Vous pouvez exercer ces droits en nous contactant directement à
            l'adresse{" "}
            <a href="mailto:contact@displex.fr"> contact@displex.fr</a>. Vous
            avez également le droit d'introduire une réclamation auprès de
            l'autorité de protection des données compétente.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            5. Conservation des données
          </h2>
          <p>
            Les données personnelles sont conservées pour la durée nécessaire
            aux finalités pour lesquelles elles ont été collectées, conformément
            à nos politiques de conservation des données et à la législation
            applicable.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            6. Modifications de la politique de confidentialité
          </h2>
          <p>
            Displex se réserve le droit de modifier cette politique de
            confidentialité à tout moment. Les modifications entreront en
            vigueur immédiatement après leur publication sur nos sites. Les
            utilisateurs sont invités à consulter régulièrement cette politique
            pour être informés de nos pratiques en matière de protection des
            données personnelles.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">7. Contact</h2>
          <p>
            Pour toute question ou préoccupation concernant cette politique de
            confidentialité ou le traitement de vos données personnelles,
            veuillez contacter notre responsable de la protection des données à
            <a href="mailto:contact@displex.fr"> contact@displex.fr</a>.
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
