import React from "react";

function LegalMentions() {
  return (
    <div className="bg-gray-50 text-black p-5 mb-16 lg:p-10">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-10">
          Mentions Légales
        </h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            1. Informations légales
          </h2>
          <p className="mb-2">
            Conformément aux dispositions des articles 6-III et 19 de la Loi
            n°2004-575 du 21 juin 2004 pour la confiance dans l'économie
            numérique, dite L.C.E.N., nous portons à la connaissance des
            utilisateurs et visiteurs du site :{" "}
            <a
              href="https://displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              displex.fr
            </a>{" "}
            ainsi que son sous-domaine où se trouve l'application{" "}
            <a
              href="https://app.displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              app.displex.fr
            </a>{" "}
            les informations suivantes :
          </p>
          <ul className="list-disc pl-5">
            <li>Statut du propriétaire : société</li>
            <li>Nom de la Société : SAS Displex</li>
            <li>Adresse : 36 rue de Grandvilliers, 60000 Beauvais</li>
            <li>Téléphone : +33 7 80 98 11 11</li>
            <li>Au Capital de : 1000 €</li>
            <li>SIRET : 93213971000010</li>
            <li>Adresse de courrier électronique : contact@displex.fr</li>
          </ul>
          <p className="mt-2">
            Le Créateur du site est la SAS Displex
            <br />
            Le Responsable de la publication est : Monsieur Damien AUBRY
            <br />
            Contacter le responsable de la publication : contact@displex.fr
            <br />
            Le responsable de la publication est une personne physique
            <br />
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">2. Hébergement</h2>
          <p className="mb-2">
            Le site est hébergé par SiteGround Hosting Ltd.
            <br />
            Téléphone : +44 20 7183 1197
            <br />
            Adresse : 3rd Floor, 11-12 St. James's Square, London, SW1Y 4LB,
            United Kingdom
            <br />
            Adresse électronique : support@siteground.com
            <br />
            Site web :{" "}
            <a
              href="https://www.siteground.com"
              className="text-blue-600 hover:text-blue-800"
            >
              www.siteground.com
            </a>
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            3. Politique de confidentialité
          </h2>
          <p>
            La politique de confidentialité, qui inclut les informations sur la
            collecte des données personnelles, le traitement et les droits des
            utilisateurs, est accessible depuis le lien suivant :{" "}
            <a href="/terms" className="text-blue-600 hover:text-blue-800">
              Politique de Confidentialité
            </a>
            .
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            4. Conditions générales d'utilisation du site et des services
            proposés
          </h2>
          <p>
            L’utilisation du site{" "}
            <a
              href="https://displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              displex.fr
            </a>{" "}
            ainsi que son sous-domaine où se trouve l'application{" "}
            <a
              href="https://app.displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              app.displex.fr
            </a>{" "}
            implique l’acceptation pleine et entière des conditions générales
            d’utilisation décrites ci-après. Ces conditions d’utilisation
            peuvent être modifiées ou complétées à tout moment, les utilisateurs
            du site sont donc invités à les consulter de manière régulière.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">
            5. Droits d’auteur et propriété intellectuelle
          </h2>
          <p>
            Tous les éléments du site{" "}
            <a
              href="https://displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              displex.fr
            </a>{" "}
            ainsi que son sous-domaine où se trouve l'application{" "}
            <a
              href="https://app.displex.fr"
              className="text-blue-600 hover:text-blue-800"
            >
              app.displex.fr
            </a>
            , y compris les textes, présentations, illustrations, photographies,
            arborescences et mise en forme sont, sauf documents publics et
            précisions supplémentaires, la propriété intellectuelle exclusive de
            la société Displex ou de ses partenaires.
          </p>
        </section>
      </div>
    </div>
  );
}

export default LegalMentions;
