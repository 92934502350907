import React from "react";

const HeroSection = () => {
  return (
    <section id="top" className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-12 sm:px-16 lg:px-24">
        <div className="grid grid-cols-1 gap-8 items-center">
          <div className="hero-content text-center py-24 md:py-36">
            <h1 className="text-5xl md:text-8xl font-bold mb-6">
              Votre{" "}
              <span className="bg-gradient-to-r from-indigo-500 to-pink-500 text-transparent bg-clip-text">
                affichage dynamique
              </span>{" "}
              simplifié
            </h1>
            <p className="text-lg md:text-1xl mb-8 pt-5  max-w-3xl mx-auto">
              Améliorez la gestion de vos écrans et contrôlez vos contenus
              <br />
              visuels à distance grâce à une solution innovante et accessible.
            </p>
            <div className="flex justify-center items-center gap-4">
              <a
                href="#features"
                className="text-center font-bold py-3 px-6 rounded-lg transition duration-600 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 text-white hover:bg-pink-500"
                role="button"
              >
                Découvrir plus
              </a>
              <a
                href="https://tally.so/r/wLGod2"
                className="custom-button font-bold py-3 px-6 rounded-lg transition duration-600 ease-in-out bg-white text-gray-800 hover:bg-gray-600 hover:text-white"
                role="button"
              >
                Nous contacter
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
