import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Features from "./components/Features";
import PricingSection from "./components/PricingSection";
import FAQSection from "./components/FAQSection";
import Footer from "./components/Footer";
import "./tailwind-output.css";
import "./displex-style.css";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LegalMentions from "./pages/LegalMentions";
import CookieBanner from "./components/CookieBanner";
import SupportConfirmation from "./pages/SupportConfirmation";
import Display from "./components/Display";

const App = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/*<CookieBanner />*/}
      <Header />
      <div className="flex-grow flex flex-col">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <Display />
                <Features />
                <PricingSection />
                <FAQSection />
              </>
            }
          />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal-mentions" element={<LegalMentions />} />
          <Route path="/confirmation" element={<SupportConfirmation />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
