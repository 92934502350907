import React, { useState } from "react";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Tooltip } from "react-tooltip";

const PricingSection = () => {
  const [plan, setPlan] = useState("annual");
  const [additionalPlayersBasic, setAdditionalPlayersBasic] = useState(0);
  const [additionalPlayersAdvanced, setAdditionalPlayersAdvanced] = useState(0);

  const handleToggle = () => {
    setPlan(plan === "monthly" ? "annual" : "monthly");
  };

  const incrementPlayers = (type) => {
    if (type === "basic" && additionalPlayersBasic < 6) {
      setAdditionalPlayersBasic(additionalPlayersBasic + 1);
      setAdditionalPlayersAdvanced(0); // Reset advanced players
    } else if (type === "advanced" && additionalPlayersAdvanced < 6) {
      setAdditionalPlayersAdvanced(additionalPlayersAdvanced + 1);
      setAdditionalPlayersBasic(0); // Reset basic players
    }
  };

  const decrementPlayers = (type) => {
    if (type === "basic" && additionalPlayersBasic > 0) {
      setAdditionalPlayersBasic(additionalPlayersBasic - 1);
    } else if (type === "advanced" && additionalPlayersAdvanced > 0) {
      setAdditionalPlayersAdvanced(additionalPlayersAdvanced - 1);
    }
  };

  const handleSubscription = (type, additionalPlayers) => {
    // Logic to handle subscription selection
    window.location.href = "https://app.displex.fr/subscription";
  };

  const details = {
    basic: {
      title: "Basique",
      basePrice: plan === "annual" ? 19 : 24,
      additionalPlayerPrice: plan === "annual" ? 4 : 5,
      billedText:
        plan === "annual"
          ? "/mois facturé annuellement"
          : "/mois facturé mensuellement",
      description: "Idéal pour les petits commerces et exposants.",
      basePlayers: 1,
      baseStorageMb: 250,
      features: [
        {
          text: "Diffusion d'image et de vidéo",
          description: "Diffusion en boucle de vos médias.",
        },
      ],
    },
    advanced: {
      title: "Avancé",
      basePrice: plan === "annual" ? 29 : 34,
      additionalPlayerPrice: plan === "annual" ? 4 : 5,
      billedText:
        plan === "annual"
          ? "/mois facturé annuellement"
          : "/mois facturé mensuellement",
      description:
        "Un ciblage plus précis des publicités avec des fonctionnalités avancées.",
      basePlayers: 2,
      baseStorageMb: 500,
      features: [
        {
          text: "+ Programmation des diffusions",
          description: "Création et programmation des playlists.",
        },
      ],
    },
  };

  const savingsText =
    plan === "annual"
      ? `Économisez ${
          60 + (additionalPlayersBasic || additionalPlayersAdvanced)
        }€ en choisissant l'abonnement annuel!`
      : "";

  return (
    <div className="bg-white py-8" id="pricing">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            Sélectionnez un abonnement
          </h1>
          <p className="text-md text-gray-700 mb-8">
            Choisissez l'abonnement qui convient le mieux à vos besoins et
            passez à un plan supérieur à tout moment.
          </p>
        </div>
        <div className="flex items-center justify-center mb-4 cursor-pointer">
          <span
            className={`text-lg font-semibold ${
              plan === "monthly" ? "text-gray-900" : "text-gray-400"
            }`}
          >
            Mensuel
          </span>
          <label htmlFor="plan-toggle" className="mx-4 cursor-pointer">
            <input
              id="plan-toggle"
              type="checkbox"
              className="hidden"
              checked={plan === "annual"}
              onChange={handleToggle}
            />
            <div
              className={`w-14 h-8 rounded-full p-1 transition duration-300 ease-in-out ${
                plan === "annual"
                  ? "bg-gradient-to-r from-indigo-500 to-pink-500"
                  : "bg-gray-200"
              }`}
            >
              <div
                className={`w-6 h-6 bg-white rounded-full shadow-md transform ${
                  plan === "annual" ? "translate-x-6" : ""
                }`}
              ></div>
            </div>
          </label>
          <span
            className={`text-lg font-semibold ${
              plan === "annual" ? "text-gray-900" : "text-gray-400"
            }`}
          >
            Annuel
          </span>
        </div>
        <div className="text-center">
          {savingsText && (
            <p className="text-white text-sm bg-green-500 rounded-md px-2 py-1 text-center inline-block">
              {savingsText}
            </p>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {["basic", "advanced"].map((type) => {
            const additionalPlayers =
              type === "basic"
                ? additionalPlayersBasic
                : additionalPlayersAdvanced;
            const reachedLimit = additionalPlayers >= 6;
            const {
              title,
              basePrice,
              additionalPlayerPrice,
              billedText,
              description,
              basePlayers,
              baseStorageMb,
              features,
            } = details[type];
            const totalPrice =
              basePrice + additionalPlayers * additionalPlayerPrice;
            const playerFeature = `Gestion de ${
              basePlayers + additionalPlayers
            } player${basePlayers + additionalPlayers > 1 ? "s" : ""}.`;
            const storageFeature = `${
              baseStorageMb + additionalPlayers * 250
            } Mo de stockage.`;

            return (
              <div
                key={type}
                className="flex flex-col w-full bg-white rounded-lg overflow-hidden shadow-xl"
              >
                <div className="p-8 flex flex-col flex-1">
                  <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                    {title}
                  </div>
                  <div className="mt-1 text-lg leading-tight font-medium text-black">
                    {description}
                  </div>
                  <div className="mt-4">
                    <div className="text-5xl font-bold text-gray-900">{`${totalPrice}€`}</div>
                    <div className="text-base text-gray-500 mb-4">
                      {billedText}
                    </div>
                    <div className="flex flex-col justify-start flex-1">
                      <ul className="space-y-2">
                        <li>
                          {playerFeature}
                          <InformationCircleIcon
                            className="inline-block w-5 h-5 ml-2 text-gray-500"
                            data-tooltip-id={`${type}-playerTooltip`}
                          />
                          <Tooltip id={`${type}-playerTooltip`}>
                            Nombre de player que vous pouvez gérer.
                          </Tooltip>
                        </li>
                        <li>
                          {storageFeature}
                          <InformationCircleIcon
                            className="inline-block w-5 h-5 ml-2 text-gray-500"
                            data-tooltip-id={`${type}-storageTooltip`}
                          />
                          <Tooltip id={`${type}-storageTooltip`}>
                            Espace de stockage pour vos médias.
                          </Tooltip>
                        </li>
                        {features.map((feature, index) => (
                          <li key={index}>
                            {feature.text}
                            <InformationCircleIcon
                              className="inline-block w-5 h-5 ml-2 text-gray-500"
                              data-tooltip-id={`${type}-featureTooltip-${index}`}
                            />
                            <Tooltip id={`${type}-featureTooltip-${index}`}>
                              {feature.description}
                            </Tooltip>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="px-8 py-8 mt-auto">
                  <div className="mb-4">
                    <label
                      htmlFor="player-count"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Gestion de player supplémentaire :
                      <div className="text-base text-gray-500 mb-4">
                        {additionalPlayerPrice}€ / unité
                      </div>
                    </label>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => decrementPlayers(type)}
                        className="font-bold py-1 px-3 rounded"
                        disabled={additionalPlayers <= 0}
                      >
                        <MinusCircleIcon className="w-6 h-6" />
                      </button>
                      <span>{additionalPlayers}</span>
                      <button
                        onClick={() => incrementPlayers(type)}
                        className="font-bold py-1 px-3 rounded"
                        disabled={reachedLimit}
                      >
                        <PlusCircleIcon className="w-6 h-6" />
                      </button>
                    </div>
                    {reachedLimit && (
                      <p className="text-red-500 text-sm mt-2">
                        Il n'est pas possible d'avoir plus de players en gestion
                        sur ce type d'abonnement. Veuillez-nous contacter pour
                        avoir plus d'informations sur la formule entreprise.
                      </p>
                    )}
                  </div>
                  <button
                    className="w-full text-center text-white font-bold py-2 px-4 rounded-lg transition duration-200 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 hover:bg-pink-500"
                    onClick={() => handleSubscription(type, additionalPlayers)}
                    disabled={reachedLimit}
                  >
                    Choisir
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
